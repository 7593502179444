
@media all and (max-width: 991px){
  .section-header{
    font-size: 34px;
  }
  .common-text{
    font-size: 16px;
    margin-top: 20px;
  }
  .img-wrap, .section-text, .content--r .section-text, .address .img-wrap{
    max-width: 320px;
  }

  .safe .section-text, .team .section-text, .address .section-text{
    margin: 0;
  }
  .safe, .team, .address{
    align-items: center;
  }

  .check-block__input-wrapper:before{
    left: 10px;
    font-size: 18px;
  }
  .check-block__input-field, .check-block__input-button{
    height: 40px;
    line-height: 40px;
  }
  .check-block__input-button{
    font-size: 14px;
    width: 132px;
  }
  .check-block__input-field {
    padding: 5px 20px 5px 24px;
    font-size: 16px;
    color: #00104b;
  }

  .head-nav, .foot-nav, .content{
    max-width: 767px;
  }
  .content, .content--r{
    justify-content: space-between;
  }



  //header,footer
  a.logo, footer a.logo{
    width: auto;
    max-width: 143px;
    height: 44px;
    img{
      width: auto;
      height: 100%;
    }
  }
  footer a.logo{
    img{
      height: 44px;


    }
  }
  .nav-link {
    margin-left: 20px;
    font-size: 15px;
  }


  .foot-nav{
    min-height: 380px;
  }
  .link-list{
    justify-content: space-between;
  }
  .foot-nav__links{
    margin-right: 0;
  }
  footer .nav-link--cap{
    min-height: 80px;
  }
  .foot-nav .copyright-text{
    font-size: 13px;
    padding-left: 10px;
  }


  //*********************HOME**************************/

  //Board
  .board{
    min-height: 500px;

  }
  .board-text {
    max-width: 360px;
    min-height: auto;
  }
  .sup-item{
    font-size: 12px;
    padding: 5px 8px 4px;
  }
  .board-text__cap {
    font-size: 34px;
    margin-bottom: 25px;
  }
  .board-text__text {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .board-card{
    width: 280px;
    height: auto;
    font-size: 16px;
    p:first-child{margin-top: 15px}
    p:last-child{margin-bottom: 15px}
  }
  .board-img{
    min-height: auto;
  }
  .board-card__item{
    font-size: 14px;
    width: 216px;
    height: 36px;
    &:before{
      width: 28px;
    }
  }
  .board-card__item--code{
    font-size: 12px;
    margin-bottom: 45px;
    &:before{
      left: calc(50% - 12px);
      top: auto;
      width: 24px;
      height: 59px;
      bottom: -62px;
    }

  }
  .vid{
    margin-top: 30px;
  }
  .vid__label{
    font-size: 16px;
  }
  .vid__img{
    width: 100px;
    height: 60px;
    img{
      width: 40px;
    }
  }
  .board--bot .img-wrap{
    margin: 0 0 -7px 0;
  }
  .board--bot .board-text{
    margin: 80px 0 0 0;
    max-width: 320px;
    &__text{
      width: auto;
    }
  }
  .sup-item{
    width: auto;
  }
  //Supported_by
  .supported_by{
    font-size: 18px;
  }
  .supported_by__more{
    margin: 20px auto 0 auto;
  }

  .what, .check-section, .benefits, .safe, .team,
  .address, .commission, .board--bot, .faq{
    margin-top: 80px;
  }

  //Benefits
  .benefits{
    align-items: stretch;
    margin-top: 80px;
  }
  .benefits-item{
    width: 220px;
    height: auto;
    padding: 15px;
  }
  .benefits-icon{
    width: 42px;
    height: 42px;
  }
  .benefits-cap{
    font-size: 18px;
  }
  .benefits-text{
    font-size: 14px;
  }

  .check-section{
    margin-top: 80px;
    font-size: 18px;
  }
  .check-section__text{
    margin-bottom: 30px;
  }

  //Safe
  .safe{
    margin-top: 80px;
  }

  //FAQ
  button.accordion{
    height: 80px;
    font-size: 18px;
  }



  /**********************SELECT********************/
  .board--select{
    min-height: 100px;
    font-size: 30px;
  }
  .name-label{
    font-size: 18px;
  }
  .select-name .name-label{
    max-width: 302px;
  }
  .name-current{
    font-size: 24px;
    max-width: 287px;
  }
  .select-name .check-block{
    height: 80px;
    padding: 0 15px;
  }
  .product-item {
    padding: 20px 15px;
    max-width: 325px;
    min-height: 450px;
    &__icon{
      width: 74px;
      height: 74px;
      img{
        width: 54px;
        height: auto;
      }
    }
  }
  .product-item__name{
    font-size: 18px;
  }
  .product-item__price .amount{
    font-size: 38px;
  }
  .product-item .feature{
    font-size: 14px;
  }
  .product-item__buy{
    font-size: 14px;
    width: 180px;
    height: 40px;
  }
  /**********************CHECKOUT********************/

  .summary{
    width: 240px;
    height: auto;
    padding: 15px;
    min-height: auto;
  }
  .checkout__side{
    width: 240px;
  }

  .summary__cap{
    font-size: 18px;
  }
  .summary__product{
    font-size: 14px;
    padding: 10px 0;
  }
  .summary__prices{
    font-size: 14px;
    padding: 10px 0;
  }
  .summary__total-sup{
    font-size: 14px;
  }
  .summary__total-amount{
    font-size: 28px;
  }
  .summary__btn{
    width: 100%;
    height: 40px;
    border-radius: 2px;
  }

  .checkout{
    font-size: 14px;
    ::-webkit-input-placeholder {font-size: 14px;}
    ::-moz-placeholder          {font-size: 14px;}
    :-moz-placeholder           {font-size: 14px;}
    :-ms-input-placeholder      {font-size: 14px;}
  }
  .checkout .check-block__input-field{
    font-size: 14px;
    height: 34px;
    padding: 5px 10px;
    max-width: none;
    line-height: 34px;
    margin-right: 15px;
    &--large{
      max-width: none;
    }
    &--code{
      max-width: none;
      margin: 0;
      border-right: 0;
    }
  }
  .checkout .check-block__input-button{
    height: 34px;
    line-height: 34px;
  }
  .checkout__forms{
    max-width: 420px;
  }
  .checkout .name-current{
    padding: 15px;
    height: 80px;
  }
  .checkout .name-label{
    font-size: 18px;
  }

  .radio-block__option{
    height: 50px;
  }
  .fieldset-block {
    padding: 15px 15px 15px 35px;
  }


  .select-wrapper{
    max-width: 178px;
    width: 100%;
    &--large{
      margin-bottom: 20px;
      max-width: none;
    }
    &--small{
      width: 100%;
      margin-right: 15px;
    }
  }
  .checkout .check-block__input-field--small, .select-wrapper--small{
    max-width: 81px;
  }

  .selectric .label{
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .selectric .button{
    height: 32px;
  }
  .selectric-items ul, .selectric-items li{
    font-size: 14px;
  }



  .confirm__message{
    font-size: 28px;
  }
  .confirm {
    margin: 140px auto 100px auto;
    display: block;
  }

  .payment-brand--credit-card{
    width: 30px;
    height: 21px;
  }
  .payment-brand--paypal{
    width: 70px;
    height: 19px;
  }
  .payment-brand--btc{
    width: 80px;
    height: 18px;
  }

  .switcher-block{
    min-height: 60px;
    padding: 15px;
  }
  .checkbox:checked + p.switch:before{
    top: 0;
  }
  .checkbox:not(checked) + p.switch:before{
    top: 0;
  }
  .checkbox:not(checked) + p.switch:after{
    top: 2px;
  }
}


@media all and (max-width: 767px){

  .checkout-body footer{
    padding-bottom: 132px;
  }

  .head-nav, .content, .foot-nav{
    padding: 0 30px;
  }
  .head-nav, .foot-nav, .content {
    max-width: 575px;
  }
  footer{
    padding: 15px 0;
  }
  .nav-link {
    margin-left: 15px;
    font-size: 14px;
  }


  .img-wrap, .section-text, .content--r
  .section-text, .address .img-wrap{
    max-width: 245px;
  }

  .board{
    padding: 30px 0;
    .content{
      flex-direction: column;
    }
  }
  .board--select{
    .content{
      align-items: flex-start;
    }
  }
  .board-card{
    margin: 40px auto;
    width: 360px;
    font-size: 18px;
  }
  .board-card__item{
    width: 240px;
  }
  .board-text__text{
    font-size: 20px;
  }

  .supported_by{
    &__text{
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    &__more{
      margin: 0;
    }
  }

  .vid__img {
    width: 60px;
    height: 40px;
    img{
      width: 28px;
    }
  }
  .vid__label{
    font-size: 13px;
  }

  .benefits-item{
    max-width: 164px;
    padding: 10px 5px;
  }
  .benefits-icon {
    width: 36px;
    height: 36px;
  }
  .benefits-cap{
    font-size: 15px;
  }
  .benefits-text{
    font-size: 13px;
  }

  .section-header{
    font-size: 30px;
  }
  .common-text{
    font-size: 14px;
  }


  .board--bot{
    min-height: auto;
    overflow: hidden;
    .content{
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
    }
    .board-text{
      margin: 30px 0 0 0;
    }
    .board-text__text{
      font-size: 16px;
      margin-bottom: 35px;
      height: auto;
    }

    .sub-item{
      font-size: 14px;
    }

    .img-wrap{
      margin: 0;
    }
    .board-img{
      position: absolute;
      width: auto;
      height: auto;
      max-width: 220px;
      margin: 0;
      right: auto;
      left: 30px;
      top: auto;
      bottom: -37px;
    }

  }

  button.accordion{
    height: 60px;
    font-size: 16px;
    &:before{
      right: 3px;
      width: 16px;
      height: 2px;
    }
    &:after{
      top: calc(50% - 8px);
      right: 10px;
      display: block;
      content: '';
      width: 2px;
      height: 16px;
    }
  }
  .faq-text{
    font-size: 14px;
  }

  .link-list{
    flex-wrap: wrap;
  }
  .foot-nav__links{
    &--logo{
      order: 10;
      width: 100%;
      align-items: center;
      .nav-link--cap{
        min-height: auto;
        margin: 20px 0;
      }
      br{
        display: none;
      }
    }
  }

  .select-name{
    .name-label{
      max-width: 205px;
    }
    .name-current{
      max-width: 190px;
    }
  }

  .select-product__cap{
    text-align: center;
  }
  .select-product .product-wrap{
    flex-direction: column;
    align-items: center;
  }
  .product-item{
    width: 100%;
    max-width: none;
    margin-bottom: 30px;
  }

  .checkout{
    flex-direction: column;
    align-items: center;
    &__side{
      width: 100%;
    }
  }
  .checkout__forms{
    max-width: none;
  }

  .checkout .check-block__input-field{
    max-width: 225px;
    &--small{
      max-width: 115px;
    }
    &--large{
      max-width: none;
    }
    &--code{
      max-width: none;
    }
  }

  .select-wrapper{
    max-width: 225px;
  }
  .checkout .check-block__input-field--small, .select-wrapper--small{
    max-width: 105px;
  }
  .select-wrapper--large{
    max-width: none;
  }


  .summary{
    width: 100%;
    padding: 0;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
    .form-row{
      margin: 0;
      padding: 15px;
      border-bottom: solid 1px rgba(142, 155, 165, 0.5);
    }
  }
  .summary__total{
    border: none;
    padding: 15px;
    position: relative;
  }
  .summary__cap{
    display: none;
  }
  .summary__product{
    display: none;
  }
  .summary__prices{
    padding: 0;
    display: none;
    max-height: calc(100vh - 132px);
    overflow: auto;
  }
  .summary__btn{
    //border-top-right-radius: 0;
    //border-top-left-radius: 0;
    border-radius: 0;
    width: 100%;
  }
  .summary__total, .summary__prices{
    max-width: 515px;
    margin: auto;
  }


  .summary-toggle{
    display: flex;
    transition: transform .3s linear 0s;
  }
  .summary-toggle.toggled{
    transform: rotate(180deg);
  }

  .confirm__message {
    font-size: 22px;
  }

  .foot-nav .copyright-text{
    padding: 0;
  }

}

@media all and (min-width: 768px){



  .sticky .sticky-wrap{
    position: fixed;
    top: 0;
  }

  .overscrolled .sticky-wrap{
    position: absolute;
    bottom: 0;
    top: auto;
  }

  .checkout {
    align-items: stretch;
  }
}

@media all and (max-width: 575px){
  ::-webkit-input-placeholder {font-size: 14px;}
  ::-moz-placeholder          {font-size: 14px;}
  :-moz-placeholder           {font-size: 14px;}
  :-ms-input-placeholder      {font-size: 14px;}
  .display-small{
    display: flex;
  }

  body.hide-content{
    overflow: hidden;
  }

  .head-nav, .content, .foot-nav{
    padding: 0 15px;
    max-width: 320px;
  }
  a.logo, footer a.logo{

  }
  .head-nav{
    min-height: 80px;
  }

  .head-nav__links{
    position: absolute;
    top: 79px;
    right: 100%;
    left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0 15px;
    z-index: 9999;
    height: 100%;
    width: calc(100% + 2px);
    max-height: none;
    overflow: hidden;
    transition: all .2s linear 0s;
    align-items: center;
  }

  header .nav-link{
    text-align: left;
    margin: 0;
    padding: 20px 15px;
    margin-left: 15px;
    font-size: 20px;
    width: 100%;
    max-width: 320px;
  }
  header .nav-link.underline:hover:before, header .nav-link.underline:hover:after{
    display: none;
  }
  .small-line{
    position: relative;
    &:before{
      display: block;
      content: '';
      position: absolute;
      bottom: -3px;
      width: 100%;
      height: 1px;
      background-color: #00104b;
    }
  }

  .head-nav__links.opened{
    right: -2px;
  }


  .content{
    flex-direction: column;
  }

  .board-text__cap{
    font-size: 28px;
  }
  .board-text__text{
    font-size: 18px;
  }
  .check-block__input-button{
    font-size: 12px;
  }
  .board-img{
    width: 100%;
  }
  .board-card{
    width: 100%;
    font-size: 16px;
  }

  .supported_by{
    justify-content: space-between;
    flex-direction: row;
    margin: 40px auto 0;
  }
  .supported_by__text{
    display: block;
  }
  .supported_by .check-mark{
    margin: 0 -40px 0 20px;
    display: inline;
    float: left;
  }
  .supported_by__more{
    margin: 30px auto 0 auto;
  }

  .section-img{
    width: 100%;
    max-width: none;
  }
  .section-text{
    width: 100%;
    max-width: none;
    margin-top: 30px;
  }
  .benefits-item{
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
    padding: 30px;
  }
  .benefits-icon{
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }
  .benefits-cap{
    font-size: 16px;
  }
  .benefits-text{
    font-size: 14px;
  }

  .check-block__input-field{
    font-size: 14px;
  }

  .check-block__input-wrapper:before {
    left: 12px;
    font-size: 16px;
  }

  .section-text{
    order: 2;
  }
  .section-img{
    order: 0;
  }


  .board--bot {
    .content{
      flex-direction: column;
      align-items: center;
    }
    .board-img__pic--large{
      display: none;
    }
    .board-img__pic--small{
      display: block
    }
    .img-wrap{
      margin-bottom: -25px;
    }
    .board-img{
      position: relative;
      width: auto;
      height: auto;
      max-width: 230px;
      margin: 0;
      right: auto;
      left: auto;
      top: auto;
      bottom: auto;
    }

  }

  .img-wrap, .section-text, .content--r
  .section-text, .address .img-wrap{
    max-width: none;
  }
  .safe .section-text, .team .section-text,
  .address .section-text{
    margin-top: 30px;
  }

  .form-row{
    flex-direction: column;
  }
  .checkout .check-block__input-field{
    max-width: none;
  }
  .checkout select.check-block__input-field{
    padding-right: 25px;
  }
  .strength-bar{
    margin: 10px 0 0 0;
  }

  .select-name .check-block{
    flex-direction: column;
    height: auto;
    max-height: none;
    padding: 15px;
  }
  .select-name .name-current{
    font-size: 24px;
    height: auto;
    max-height: none;
    max-width: none;
  }
  .select-name__holder{
    margin-top: 10px;
  }
  .select-name .name-label{
    display: none;
    max-width: none;
  }

  .form-row{
    margin: 0;
  }
  .checkout .check-block__input-field{
    margin: 10px 0;
  }
  .checkout .check-block__input-field--code{
    margin: 0;
  }
  .checkout .select-wrapper{
    margin: 10px 0;
    max-width: none;
    .check-block__input-field{
      margin: 0;
    }
  }

  footer{
    padding-top: 80px;
  }
  .foot-nav__links{
    width: 48%;
    margin-bottom: 20px;
  }
  footer .nav-link--cap{
    min-height: 40px;
    align-items: flex-start;
    line-height: 1.3;
  }
  .foot-nav__links--logo{
    width: 100%;
  }

  .summary{
    .form-row{
      flex-direction: row;
    }
  }
  .summary__btn{
    width: 100%;
  }
  .confirm {
    margin: 60px auto 60px auto;
    display: block;

  }
  .confirm__message{
    font-size: 16px;
  }
  .confirm-btn{
    width: 100%;
  }
  .btn-holder{
    margin-bottom: 60px;
  }
  .select-name .name-label.display-small{
    display: block;
    font-size: 14px;
  }
  .summary__total, .summary__prices{
    max-width: 290px;
    margin: auto;
  }
}