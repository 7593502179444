header{
  background-color: #fff;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.13);
}
.head-nav{
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  min-height: 100px;
  padding: 0 40px 0 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-link{
  margin-left: 41px;
  object-fit: contain;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00224e;
  white-space: nowrap;
}
.head-nav__links{
  white-space: nowrap;
}
a.logo{
  width: 181px;
  height: 56px;
}