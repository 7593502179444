input{
  border: none;
  outline: none;
  background-color: #fff;
}

::-webkit-input-placeholder {color:#8e9ba5; font-size: 16px; font-weight: normal;}
::-moz-placeholder          {color:#8e9ba5; font-size: 16px; font-weight: normal;}
:-moz-placeholder           {color:#8e9ba5; font-size: 16px; font-weight: normal;}
:-ms-input-placeholder      {color:#8e9ba5; font-size: 16px; font-weight: normal;}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }



.radio-hidden{
  display: none;
  & + .radio-visible{
    position: relative;
    font-weight: bold;
    padding-left: 30px;
    transition: color 0.2s linear 0s;
  }
  & + .radio-visible:before{
    box-sizing: border-box;
    position: absolute;
      top: calc(50% - 9px);
      left: 0;
    display: block;
    content: '';
    height: 18px;
    width: 18px;
    border: solid 1px #8e9ba5;
    background-color: #fff;
    border-radius: 35px;
  }
  & + .radio-visible:after{
    box-sizing: border-box;
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
    display: block;
    content: '';
    height: 18px;
    width: 18px;
    border: solid 5px #1069e3;
    background-color: #fff;
    border-radius: 35px;
    opacity: 0;
    transition: all 0.2s linear 0s;
  }
  &:checked + .radio-visible{
    color: #1069e3;
  }
  &:checked + .radio-visible:after{
    opacity: 1;
  }
}

.check-block{
  display: flex;
  &__input-wrapper{
    position: relative;
    width: 100%;
    max-width: 310px;
    &:before{
      position: absolute;
      content: "$";
      display: flex;
      align-items: center;
        top: 0;
        left: 20px;
      height: 100%;
      font-size: 20px;
      line-height: 100%;
      color: #00224e;
    }
  }
  &__input-field{
    width: 100%;
    height: 50px;
    border: solid 1px #8e9ba5;
    border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    padding: 12px 37px;
    font-size: 20px;
    line-height: 50px;
    color: #8e93a5;

    border-right: none;
    &--on-dark{
      border: none;
    }
  }
  &__input-button{
    border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    background-color: #1e7dff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    height: 50px;
    width: 170px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    //transition: background-color .3s linear 0s;
    &:hover{
      background-color: #248fff;
    }
  }
}

.checkout .check-block{
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  justify-content: space-between;
  &__input-wrapper{
    width: 100%;
    max-width: 394px;
    &:before{display: none}
  }
  &__input-field{
    height: 44px;
    border-radius: 2px;
    border: solid 1px #c8cbd4;
    background-color: #ffffff;
    box-shadow: none;
    padding: 10px 15px;
    margin-right: 20px;
    max-width: 245px;
    font-size: 16px;
    color: #00224e;
    line-height: 44px;
    &:last-child{
      margin-right: 0;
    }
    &--code{
      max-width: none;
      margin: 0;
    }
    &--small{
      max-width: 112px;
      margin-right: 21px;
    }
    &--large{
      max-width: none;
      width: 100%;
    }
  }
  &__input-button{
    width: 136px;
    height: 45px;
    border-radius: 2px;
    background-color: #1069e3;
    //transition: background-color .3s linear 0s;
    &:hover{
      //background-color: #103b71;
      background-color: #248fff;
    }
  }
}

.checkout__side{
  z-index: 6;
}
.checkout select{
  cursor: pointer;
}

.checkout select:focus, .checkout select:active{
  outline: none;
  border: solid 1px #1069e3;
}

.check-block__input-field:focus,
.check-block__input-field:active{
  border: solid 1px #1069e3;
}
/*************************************CUSTOM SELECTORS**********************************************/
.selectric{
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #c8cbd4;
}
.selectric-hover, .selectric-active, .selectric-focus, .selectic-open{
  .selectric{
    border: solid 1px #1e7dff;
  }
}
.selectric .label{
  font-size: 16px;
  font-weight: normal;
  height: 42px;
  line-height: 42px;
  color: #00104b;
}
.selectric .button{
  height: 42px;
  background-color: #fff;
}
.selectric-items{
  background-color: #fff;
  border: solid 1px #1e7dff;
}
.selectric-items ul, .selectric-items li{
  font-size: 16px;
}
.selectric-items li:hover{
  background-color: #d5d5d54a;
  color: #1e7dff;
}
.select-wrapper{
  max-width: 245px;
  width: 100%;
  &--large{
    margin-bottom: 20px;
    max-width: none;
  }
  &--small{
    max-width: 112px;
    width: 100%;
  }
}


.billing-block, .switcher-block{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(142, 147, 165, 0.25);
}

.billing-block{
  padding-bottom: 35px;
  &--payment{
    padding-bottom: 0;
  }
}

.radio-block{
  &__option{
    padding: 0 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 58px;
    border-top: solid 1px #c8cbd4;
    &:first-child{
      border-top: none;
    }
  }
}
.radio-visible{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.radio-block__option.chosen{
  border-bottom: none;
}

.account-panel{
  max-height: 0;
  overflow: hidden;
}

.account-btn.chosen + .account-panel{
  max-height: none;
  overflow: visible;
}

.fieldset-block{
  padding: 20px 20px 20px 50px;
}
.fieldset-cap{
  font-weight: bold;
  margin-bottom: 15px;
}

.select-wrapper{
  position: relative;
  &--small{
    margin-right: 20px;
  }
}

.form-row{
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.strength{
  margin: 17px 0 20px 0;
}
.strength-bar{
  width: 100%;
  height: 19px;
  border-radius: 2px;
  background-color: #dadce1;
  margin-left: 15px;
}

.payment-brand{
  display: flex;
  &--credit-card{
    width: 40px;
    height: 28px;
  }
  &--paypal{
    max-width: 82px;
    height: 22px;
  }
  &--btc{
    max-width: 90px;
    height: 20px;
  }
}

.switcher-block{
  margin-top: 20px;
  padding: 20px;
  min-height: 85px;
  max-width: 280px;
  display: flex;
  align-items: center;
}


.switch-label{
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
  display: none;
}
.checkbox + p.switch:before{
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 9px;
  font-weight: 100;
}
.checkbox:not(checked) { position: absolute; opacity: 0;}
.checkbox:not(checked) + p.switch {
  position: relative;
  padding: 0 0 0 60px;
  height: 27px;
}
.checkbox:not(checked) + p.switch:before {
  content: 'OFF';
  color: #00224e;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  padding: 2px 0 0 26px;
  border-radius: 30px;
  border-color: #CDD1DA;
  box-shadow: #CDD1DA 0px 0px 0px 0px inset;
  transition: border 0.4s, box-shadow 0.4s;
  background-color: #EBECEC;
}
.checkbox:not(checked) + p.switch:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0 2px 5px rgba(0,0,0,.3);
  transition: all .2s;
}
.checkbox:checked + p.switch:before {
  content: "ON";
  padding: 2px 0 0 5px;
  width: 45px;
  height: 24px;
  color: #fff;
  box-shadow: #1069e3 0px 0px 0px 16px inset;
  transition: border 0.4s, box-shadow 0.4s, background-color 1.2s;
  background-color: #1069e3;
  border-color: #1069e3;
}
.checkbox:checked + p.switch:after { left: 26px;}