footer{
  padding: 15px 26px;
  a.logo{
    width: 181px;
    height: 56px;
  }
}
.foot-nav{
  min-height: 480px;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 27px;
  .copyright-text{
    object-fit: contain;
    font-family: Circular Book, sans-serif;
    font-size: 16px;
    color: #8e93a5;
    line-height: 1.88;
    padding-left: 13px;
  }
}
.link-list{
  display: flex;
  width: 100%;
}
.foot-nav__links{
  display: flex;
  flex-direction: column;
  margin-right: 85px;
  align-items: flex-start;
  &:last-child{
    margin-right: 0;
  }
}
footer .nav-link{
  line-height: 1.88;
  color: #8e93a5;
  margin: 0;
  text-align: left;
  &--cap{
    color: #00104b;
    font-weight: bold;
    min-height: 100px;
    display: flex;
    align-items: center;
    white-space: normal;
  }

}
footer .nav-link.underline:before, footer .nav-link.underline:after{
  bottom: 1px;
}
footer .nav-link.underline:hover:before, footer .nav-link.underline:hover:after{
  background: #8e93a5;
}