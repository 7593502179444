*{
  box-sizing: border-box;
}
body{
  background-color: #fff;
  object-fit: contain;
  font-family: Circular Book, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #00104b;
}
a, a:hover, a:link, a:visited{
  text-decoration: none;
  cursor: pointer;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  text-align: center;
}
h1{
  font-size: 62px;
  text-transform: uppercase;

}
h4{
  font-size: 44px;
}

p{
  margin: 0;
}

ul,li{
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.content, header,footer{
 img {
   width: 100%;
   height: auto;
 }
}

.clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.external{
  overflow: hidden;
  position: relative;
}



.btn{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1069e3;
  margin: auto;
}

.display-small{
  display: none;
}

.bg-grey{
  background-color: #f2f2f3;
}

.fc-blue{
  color: #1069e3;
}
.fc-grey{
  color: #8e93a5;
}
.section-header{
  font-size: 48px;
  font-weight: 900;
  line-height: 1.25;
  color: #00224e;
  text-align: left;
  &--faq{
    text-align: center;
    line-height: 1.21;
    color: #00104b;
    text-transform: uppercase;
  }
  &--select{
    font-size: 32px;
    font-weight: bold;
    line-height: 1.88;
    color: #ffffff;
  }
}

.common-text{
  font-size: 18px;
  line-height: 1.33;
  color: #00104b;
  margin-top: 28px;
  max-width: 400px;
}

.text-link{
  font-weight: bold;
  color: #00104b;
}

@font-face {
  font-family: "Circular Book";
  src: url("../fonts/CircularStd-Book.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Circular Book";
  src: url("../fonts/CircularStd-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Circular Book";
  src: url("../fonts/CircularStd-Black.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
}
header, footer, .wrapper{
  margin: 0 auto;
}

.menu-icon{
  width: 36px;
  height: 36px;
  box-shadow: 0 0 2px 0px #00104b;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  hr{
    transition: all .3s linear 0s;
    background-color: #797c87;
  }
}

.menu-icon.toggled{
  position: relative;
  hr{
    position: absolute;
    top: 16px;
    &:first-child{
      transform: rotate(45deg);
    }
    &:first-child + hr{
      position: relative;
      opacity: 0;
      display: none;
    }
    &:last-child{
      transform: rotate(-45deg);
    }
  }
}

.menu-line{
  width: 24px;
  height: 2px;
  background-color: #00104b;
  border: none;
  margin: 2px 0;
}

.underline{
  position: relative;
  text-decoration: none;
  &:before{
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    height: 1px;
    left: 50%;
    right: 50%;
    transition: all .2s linear 0s;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    height: 1px;
    left: 50%;
    right: 50%;
    transition: all .2s linear 0s;
  }
}
.underline:hover{
  &:before{
    background: #00104b;
    left: 0;
  }
  &:after{
    background: #00104b;
    right: 0;
  }
}

.fc-blue.underline:hover:before, .fc-blue.underline:hover:after{
  background-color: #1069e3;
}

.content{
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: flex-start;
  &--r{
    justify-content: flex-end;
    .section-img{
      justify-content: flex-end;
      display: flex;
    }
    .section-text{
      max-width: 400px;
    }

  }
}
.section-text{
  width: 50%;
}
.section-img{
  width: 49%;
  img{
    display: block;
  }
}
.img-wrap{
  max-width: 380px;
}
.board{
  background: #193c6a; /* Old browsers */
  background: -moz-linear-gradient(top,  #193c6a 0%, #00224e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #193c6a 0%,#00224e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #193c6a 0%,#00224e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#193c6a', endColorstr='#00224e',GradientType=0 ); /* IE6-9 */
  min-height: 580px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content{
    justify-content: space-between;
  }
  &--select{
    min-height: 109px;
  }
  &--bot{
    margin-top: 140px;
    .board-img{
      overflow: hidden;
      margin-top: 80px;
    }
    .board-img__pic--small{display: none}

    .img-wrap{
      max-width: 460px;
      margin: auto auto -7px 42px;
    }
    .board-text{
      margin: 87px auto 0 -42px;
    }
    .board-text__text{
      width: 400px;
      height: 120px;
      font-size: 18px;
      line-height: 1.33;
      color: #bbc7dd;
      margin-bottom: 85px;
    }
  }
}

.board-text{
  max-width: 500px;
  min-height: 440px;
  &__cap{
    font-size: 48px;
    font-weight: 900;
    line-height: 1.25;
    color: #ffffff;
    margin-bottom: 25px;
  }
  &__text{
    font-size: 32px;
    line-height: 1.25;
    color: #bbc7dd;
    margin-bottom: 40px;
  }
}
.board-img{
  min-height: 440px;
}

.sup-item{
  display: inline-block;
  line-height: 1;
  padding: 5px 10px 4px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border-radius: 35px;
  background-color: #00b360;
  text-transform: uppercase;
  margin-bottom: 13px;
}
.sub-item{

  height: 24px;
  font-size: 18px;
  line-height: 1.33;
  color: rgba(187, 199, 221, 0.5);
}

.board-card{
  width: 350px;
  height: 414px;
  border-radius: 20px;
  //backdrop-filter: blur(1px);
  box-shadow: 0 20px 50px 0 rgba(0, 15, 35, 0.5);
  border: 1px #375a88 solid;
  background-image: linear-gradient(134deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0) 49%), linear-gradient(147deg, #3f689e 12%, #0d315f 70%), linear-gradient(139deg, #6b8dba 2%, #214573 84%);
  //background-origin: border-box;
  //background-clip: content-box, content-box, border-box;
  font-size: 20px;
  line-height: 2;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;
  p:first-child{
    margin: 25px auto 10px auto;
  }
  &__item{
    width: 258px;
    height: 41px;
    border-radius: 21px;
    box-shadow: 0 2px 6px 0 rgba(0, 34, 78, 0.57);
    background-image: linear-gradient(to bottom, #ffffff, #bdcbde 98%);
    font-size: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #00224e;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    &:before{
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      content: "";
      width: 34px;
      height: 34px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &--code{
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 2.2px;
      color: #00224e;
      margin-bottom: 80px;
      &:before{
        background-image: url("../img/combined-shape2.svg");
        bottom: -70px;
        left: calc(50% - 20px);
        top: auto;
        width: 40px;
        height: 59px;
      }
    }
    &--btc:before{
      background-image: url("../img/btc.svg");
    }
    &--eth:before{
      background-image: url("../img/eth.svg");
    }
    &--xrp:before{
      background-image: url("../img/xrp.svg");
    }
  }
}

.supported_by{
  min-height: 85px;
  margin: 20px auto;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #00104b;
  justify-content: space-between;
  flex-wrap: wrap;
  &--select{
    margin-top: 70px;
  }
  .check-mark{
    width: 21px;
    height: 20px;
  }
  &__text{
    img{
      margin-right: 9px;
    }
  }
  &__more{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 142px;
    height: 25px;
    border-radius: 35px;
    background-color: #00224e;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;

    user-select: none;
  }
}

.what{
  margin-top: 89px;
  .img-wrap{
    max-width: 446px;
  }
}
.vid{
  margin-top: 46px;
  &__img{
    width: 110px;
    height: 70px;
    border-radius: 5px;
    box-shadow: 0 3px 14px 0 rgba(44, 51, 59, 0.21);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: transform 0.7s ease 0s;
    img{
      width: 50px;
    }
  }
  &__link{
    display: flex;
    font-size: 18px;
    line-height: 1.33;
    color: #8e93a5;
    align-items: center;
    justify-content: left;
  }
}
.vid__link:hover .vid__img{
  transform: scale(1.2);
}

.benefits{
  width: 100%;
  margin-top: 102px;
  justify-content: space-between;
}
.benefits-item{
  width: 290px;
  height: 220px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #f6f6f6, #e8e8ed);
  text-align: center;
  padding: 25px;
}
.benefits-icon{
  margin: 0 auto;
  width: 62px;
  height: 62px;
}
.benefits-cap{
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #00224e;
  margin: 10px auto;
}
.benefits-text{
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #8e93a5;
}

.check-section{
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 122px;
  color: #00224e;
  &__text{
    margin-bottom: 44px;
  }
  .check-block{
    justify-content: center;
  }
}

.safe{
  margin-top: 120px;
  .section-text{
    margin-top: 37px;
  }
}

.team{
  margin-top: 140px;
  align-items: flex-start;
  .section-text{
    margin-top: 36px;
  }
}

.address{
  margin-top: 70px;
  align-items: flex-start;
  .section-text{
    margin-top: 125px;
  }
  .img-wrap{
    max-width: 400px;
  }
}

.commission{
  .section-img{
    margin-top: 13px;
  }
}

.faq{
  display: block;
  margin-top: 120px;
  &--select{
    margin-top: 70px;
  }
}
.faq-content{
  margin-top: 46px;
}
button.accordion{
  padding-right: 22px;
  font-size: 20px;
  color: #00224e;
  width: 100%;
  height: 94px;
  text-align: left;
  border-bottom: solid 1px rgba(142, 147, 165, 0.5);
  position: relative;
  &:first-child{
    border-top: solid 1px rgba(142, 147, 165, 0.5);
  }
  &:before{
    position: absolute;
      top: calc(50% - 1px);
      right: 0;
    display: block;
    content: '';
    width: 23px;
    height: 3px;
    background-color: #1069e3;
  }
  &:after{
    position: absolute;
      top: calc(50% - 11px);
      right: 10px;
    display: block;
    content: '';
    width: 3px;
    height: 23px;
    background-color: #1069e3;
    transition: all 0.2s linear 0s;
  }
}
button.accordion.active{
  box-shadow: 0 10px 10px -5px rgba(158, 158, 158, 0.15);
  border-bottom: none;
  &:after{
    transform: rotate(90deg);
  }
}
.panel{
  max-height: 0;
  overflow: hidden;
  box-shadow: 0 -10px 10px -5px rgba(158, 158, 158, 0.15) inset;
  transition: all 0.2s linear 0s;
}

.faq-text{
  font-size: 16px;
  padding: 0 20px;
  margin-top: 20px;
  &:last-child{
    margin-bottom: 20px;
  }
}

.select-name{
  margin: 50px auto 15px auto;
  flex-direction: column;
  &__current{
    width: 100%;
    display: flex;
    margin-bottom: 18px;
  }
  .name-label{
    margin-bottom: 0;
    max-width: 430px;
  }
  &__holder{
    display: flex;
  }
  &__form{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .check-block{
    background-color: #fff;
    height: 104px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 2px 10px 0 rgba(142, 147, 165, 0.25);
  }
}
.name-label{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 2;
  width: 100%;
  color: #8e93a5;
  margin-bottom: 16px;
  &--blue{
    color: #1069e3;
  }
}
.name-current{
  font-size: 32px;
  font-weight: 900;
  line-height: 1.88;
  color: #00224e;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  align-items: center;
}

.select-product{
  margin-top: 60px;
  display: block;
  .product-wrap{
    display: flex;
    justify-content: space-between;
  }
  &__cap{
    font-size: 20px;
    font-weight: bold;
    color: #00224e;
    margin-bottom: 20px;
  }
}
.product-item{
  padding: 24px;
  width: 100%;
  max-width: 450px;
  min-height: 520px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(142, 147, 165, 0.25);
  background-color: #ffffff;
  &__icon{
    width: 96px;
    height: 96px;
    margin: 0 auto 13px auto;
    border: solid 1px #b2bfd9;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, #fbfcff, #d3dff4);
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 70px;
      height: 54px;
    }
  }
  &__name{
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
    color: #1069e3;
  }
  &__price{
    text-align: center;
    font-size: 24px;
    line-height: 0.83;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    .amount{
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      margin-right: 5px;
    }
  }
  &__period{
    height: 20px;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #8e93a5;
  }
  &__divider{
    margin: 8px auto 30px auto;
    background-color: #1069e3;
    width: 80px;
    height: 2px;
    border: none;
    box-shadow: none;
  }
  .feature{
    text-align: center;
    font-size: 16px;
    line-height: 1.25;
    color: #00224e;
    margin-bottom: 20px;
  }
  &__buy{
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 240px;
    height: 60px;
    border-radius: 5px;
    background-color: #1069e3;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.81;
    text-align: center;
    color: #ffffff;
    margin: 33px auto 0 auto;
    transition: all .2s linear 0s;
  }

}

.hvr-shadow:active, .hvr-shadow:focus, .hvr-shadow:hover{
  box-shadow: 0 18px 10px -10px rgba(0, 0, 0, 0.48);
  margin-top: 28px;
}

.checkout{
  margin-top: 53px;

  justify-content: space-between;
  align-items: stretch;
  font-size: 16px;
  .name-current{
    height: 104px;
    width: 100%;
    max-width: none;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(142, 147, 165, 0.25);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px;
  }
  &__forms{
    width: 100%;
    max-width: 580px;
  }
  .name-label{
    font-size: 20px;
    font-weight: bold;
    line-height: 2;
    color: #00224e;
  }
}
.checkout-block-cap{
  margin:  30px 0 15px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 2;
  color: #00224e;
}

.change-link{
  font-size: 14px;
  line-height: 2.86;
  text-align: right;
  color: #1069e3;
  font-weight: normal;
}

.news-offer{
  font-size: 16px;
  line-height: 1.5;
  color: #00224e;
}

.summary{
  width: 300px;
  min-height: 417px;
  border-radius: 5px;
  background-color: #00224e;
  color: #fff;
  padding: 20px;
  margin-top: 55px;
  &__cap{
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: solid 1px rgba(142, 155, 165, 0.5);
    padding-bottom: 13px;
  }
  &__icon{
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #103b71;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 0 12px;
  }
  &__product{
    font-size: 16px;
    line-height: 1.81;
    color: #ffffff;
    border-bottom: solid 1px rgba(142, 155, 165, 0.5);
    padding: 15px 0;
  }
  &__prices{
    font-size: 16px;
    line-height: 1.81;
    color: #ffffff;
    padding: 15px 0;
  }
  .summary__amount{
    display: flex;
    align-items: center;
  }
  &__item{
    padding: 5px 0;
  }
  &__total{
    border-top: solid 1px rgba(142, 155, 165, 0.5);
    padding: 12px 0;
    position: relative;
  }
  &__total-sup{
    font-size: 16px;
    line-height: 1.81;
    color: #bbc7dd;
  }
  &__total-amount{
    font-size: 32px;
    font-weight: bold;
    line-height: 0.91;
    color: #ffffff;
    padding: 6px 0;
  }
  &__btn{
    width: 260px;
    height: 60px;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color .2s linear 0s;
    &:hover{
      background-color: #77bc1f;
    }
    img{
      margin-right: 5px;
      padding-bottom: 5px;
      width: 17px;
    }
  }

}
.summary-toggle{
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 12px);
  right: 15px;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  cursor: pointer;
}

.checkout__side {
  width: 300px;
  position: relative;
}

.ssl-secured{
  margin-top: 18px;
  img{
    display: block;
    margin: 0 auto;
    max-width: 127px;
  }
}
.confirm{
  margin: 170px auto 140px auto;
  display: block;
  &__message{
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    color: #00224e;
  }
}
.confirm-btn{
  width: 240px;
  height: 60px;
  border-radius: 5px;
  margin: 0;
  transition: background-color .2s linear 0s;
  &:hover{
    background-color: #77bc1f;
  }
}
.btn-holder{
  margin-bottom: 80px;
}


